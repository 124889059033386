<template>
    <detalhe-oferta :oferta="oferta"></detalhe-oferta>
</template>

<script>
import DetalheOferta from '@/components/DetalheOfertaGlobal';
export default {
    components: {
        DetalheOferta
    },
    data() {
        return {
            DetalheOferta: {}
        };
    }
};
</script>
